<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Registrar Comisión</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;"> 
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="worker_id"
                            v-model="form.worker_id"
                            :items="workers"
                            label="Trabajador"                            
                            outlined
                            item-text="fullname"
                            item-value="id"
                            clearable
                            :rules="[() => !!form.worker_id || 'Es necesario ingresar seleccionar un trabajador']"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="service_id"
                            v-model="form.service_id"
                            :items="services_api"
                            label="Servicio"
                            outlined
                            item-text="name"
                            item-value="id"
                            clearable
                            :loading="isLoading"
                            :search-input.sync="search_service"
                            hide-no-data
                            hide-selected
                            @change="handleSelectService"
                            placeholder="Ingrese el servicio a buscar"
                            :rules="[() => !!form.service_id || 'Es necesario ingresar seleccionar un servicio']"
                            hide-details="auto"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            ref="price"
                            v-model="form.price"
                            label="Precio"
                            outlined
                            type="number"
                            prefix="S/"
                            @blur="handleBlurPrice"
                            :rules="[() => !!form.price || 'Es necesario ingresar el precio']"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            ref="quantity"
                            v-model="form.quantity"
                            label="Cantidad"
                            outlined
                            type="number"
                            @blur="handleBlurQuantity"
                            :rules="[() => !!form.quantity || 'Es necesario ingresar la cantidad']"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            ref="total"
                            v-model="form.total"
                            label="Total"
                            outlined
                            type="number"
                            prefix="S/"
                            :rules="[() => !!form.total || 'Es necesario ingresar el total']"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ServicesApi from '../../apis/Services';

export default {
    name: 'CreateCommission',
    data () {
        return {
            drawer: false,
            isLoading: false,
            search_service: null,
            services_api: [],
            form: {
                office_id: null,
                worker_id: null,
                service_id: null,
                price: null,
                quantity: null,
                total: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id'
        ]),
        ...mapState('employees', [
            'workers'
        ]),
    },
    watch: {
        async search_service (value) {
            if (value == null || value == undefined || value.length == 0) {
                return;
            }

            if (this.services_api.length > 0) {
                return;
            }

            if (this.isLoading) return

            this.isLoading = true

            let filters = {
                name: value
            }
            ServicesApi.get_all(filters)
                .then( response => {
                    this.services_api = response.data;
                })
                .catch ( error => {
                    console.log(error);
                })
                .finally( () => (this.isLoading = false));
        },
    },
    methods: {
        ...mapActions('commissions', [
            'store'            
        ]),
        async show () {
            await this.clearData();
            this.form.office_id = this.office_id;
            this.drawer = !this.drawer;
        },
        clearData() {
            this.form.office_id = null;
            this.form.worker_id = null;
            this.form.service_id = null;
            this.form.quantity = null;
            this.clearErrors();
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleCancel () {
            this.closeForm();
        },
        async handleStore () {
            if (!this.existErrors()) {
                await this.store(this.form);
                this.closeForm();
            }
        },
        handleSelectService (value) {
            this.form.price = null;
            if (value != null && value != undefined) {
                let service = this.findService(value);
                if (service) {
                    this.form.price = service.base_price;
                }
            }
        },
        findService (serviceId) {
            let service = null;
            this.services_api.forEach( element => {
                if (element.id == serviceId) {
                    service = Object.assign({}, element);
                }
            });
            return service;
        },        
        handleBlurPrice (event) {
            this.calculateTotal();
        },
        handleBlurQuantity (event) {
            this.calculateTotal();
        },
        calculateTotal () {
            let price = 0;
            let quantity = 0;
            let total = 0;
            if (this.form.price) {
                price = this.form.price;
            }
            if (this.form.quantity) {
                quantity = this.form.quantity;
            }
            total = parseFloat(price) * parseFloat(quantity);
            this.form.total = Math.round(total * 100) / 100;
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>