<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Comisiones pendientes de procesar</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-card-text class="pt-2 pb-2">
                            <template>
                                <v-row>
                                    <v-col cols="2">
                                        <v-menu
                                            v-model="menuStartDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="start_date"
                                                    v-model="filters.start_date"
                                                    label="Desde"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="[() => !!filters.start_date || 'Es necesario ingresar fecha']"                       
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.start_date" 
                                                @input="menuStartDate = false" 
                                                no-title
                                                locale="es"
                                                @change="handleChangeStartDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-menu
                                            v-model="menuEndDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="end_date"
                                                    v-model="filters.end_date"
                                                    label="Hasta"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="[() => !!filters.start_date || 'Es necesario ingresar fecha']"        
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.end_date" 
                                                @input="menuEndDate = false" 
                                                no-title
                                                locale="es"
                                                :min="minDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            ref="source"
                                            v-model="filters.source"
                                            :items="type_items"
                                            label="Tipo de item"
                                            outlined
                                            hide-details="auto"
                                            clearable
                                            :rules="[() => !!filters.source || 'Es necesario seleccionar tipo de item']"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete                             
                                                v-model="filters.worker_id"
                                                :items="workers"
                                                label="Trabajador"
                                                hide-details
                                                outlined
                                                :item-text="customTextWorkers"
                                                item-value="id"
                                                clearable
                                            ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn color="blue" dark @click="handleFilterCommision">
                                            Filtrar
                                        </v-btn>
                                        <v-btn color="green" dark class="ml-2" @click="handleProcessCalculation">
                                            Procesar
                                        </v-btn>
                                        <v-btn color="indigo" dark class="ml-2" @click="handleViewCreate">
                                            Nueva
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="pending_commissions"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                        >

                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las comisiones pendientes
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.register_date="{ item }">
                                <span>{{ item.register_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.hour_date="{ item }">
                                <span>{{ item.created_at | moment('HH:mm') }}</span>
                            </template>

                            <template v-slot:item.source="{ item }">
                                <span v-if="item.source === 'product'">
                                    PRODUCTO
                                </span>
                                <span v-else-if="item.source === 'service'">
                                    SERVICIO
                                </span>
                            </template>

                            <template v-slot:item.total="{ item }">
                                <span>{{ item.total | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <span v-if="!item.source_id">
                                    <v-icon color="red"
                                        size="18"
                                        @click="handleDelete(item)">
                                        far fa-times-circle
                                    </v-icon>
                                </span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <create-commission ref="createCommission"></create-commission>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import CreateCommission from '../../../components/commissions/CreateCommission';

export default {
    name: 'ProcessCommissions',
    components: {
        CreateCommission,
        OfficeLogin
    },
    computed: {
        ...mapState('authentication', [
            'office_id'
        ]),
        ...mapState('commissions', [
            'pending_commissions'
        ]),
        ...mapState('employees', [
            'workers'
        ]),
    },
    data() {
        return {            
            itemsBreadcrumbs: [
                {
                    text: 'Comisiones',
                    disabled: true,
                    href: '',
                }
            ],
            menuStartDate: false,
            menuEndDate: false,
            minDate: undefined,
            filters: {
                start_date: null,
                end_date: null,
                source: null,
                office_id: null,
                worker_id: null
            },
            type_items: [
                { text: 'PRODUCTO', value: 'product'},
                { text: 'SERVICIO', value: 'service'}
            ],
            headers: [
                { text: 'FECHA', value: 'register_date', sortable: false },
                { text: 'HORA', value: 'hour_date', sortable: false },
                { text: 'TRABAJADOR', value: 'worker_name', sortable: false },
                { text: 'TIPO DE ITEM', value: 'source', sortable: false },
                { text: 'ITEM', value: 'description', sortable: false },
                { text: 'CANTIDAD', value: 'quantity', sortable: false },
                { text: 'TOTAL', value: 'total', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ], 
        }
    },
    methods: {
        ...mapActions('commissions', [
            'getToProcess',
            'setPendingCommissions',
            'processCommision',
            'delete'
        ]),
        ...mapActions('employees', [
            'getWorkers'
        ]),
        customTextWorkers (item) {
            let text = item.fullname
            
            return text
        },
        handleChangeStartDate (value) {
            this.minDate = value;
            console.log(value);
        },
        handleFilterCommision () {
            if (!this.existErrors()) {
                this.getToProcess(this.filters);
            }
        },
        async clearFilters () {
            this.filters.start_date = null;
            this.filters.end_date = null;
            this.filters.source = null;
            this.filters.worker_id = null;
            await this.clearErrors();
        },
        handleProcessCalculation () {
            if (!this.existErrors()) {
                this.$swal({
                    title: "¿Desea confirmar?",
                    text: "Confirma que desea procesar el cáculo de comisiones" ,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                }).then(async (result) => {
                    if(result.value){
                        if(result.value === true){
                            await this.processCommision(this.filters);
                            this.clearFilters();
                        }
                    }                    
                })
            }
        },
        handleViewCreate () {
            this.$refs.createCommission.show();
        },
        handleDelete (commission) {            
            this.$swal({
                title: "¿Desea eliminar?",
                text: "Confirma que desea eliminar la comisión",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
            }).then( async (result) => {
                if(result.value){
                    if(result.value === true){
                        await this.delete(commission);
                    }
                }                    
            })
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.filters).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.filters[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.filters).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        load () {
            this.filters.office_id = this.office_id;
            this.getToProcess(this.filters);
        }
    },
    created () {
        this.load();
        this.getWorkers({
            office_id: this.office_id
        })
    },
    destroyed () {
        this.setPendingCommissions([]);
    }
}
</script>

<style scoped>

</style>